import Vue from 'vue';

import 'vant/lib/index.css'

import { Toast } from 'vant';
import { Field } from 'vant';
import { Loading } from 'vant';
import { Picker } from 'vant';
import { Popup } from 'vant';
import { Uploader } from 'vant';
import { ImagePreview } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Collapse, CollapseItem } from 'vant';
import { Dialog } from 'vant';
import { Rate } from 'vant';
import { Swipe, SwipeItem } from 'vant';

Vue.use(Toast); //用法：this.$toast
Vue.use(Field);
Vue.use(Loading);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Uploader);
Vue.use(ImagePreview);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Dialog);
Vue.use(Rate);
Vue.use(Swipe);
Vue.use(SwipeItem);